class Clouds extends Layer
	clouds : []
	last_cloud : 0
	webgl : false
	setup : (@layers) ->
		@largest = @size / 7
		for i in [1..24]
			@clouds.push createVector 0,0,random(@size / 20,@largest)
			# @clouds.push createVector random(@size), random(@size), random(50,150)
		@last_pos = createVector(10000,10000)

		random_pos = () ->
			padding = 0.3
			createVector random(padding, 1.0 - padding), random(padding, 1.0 - padding)

		pos = random_pos()
		dir = createVector(random(-1, 1), random(-1, 1)).normalize()
		for i in [0..300]
			@pan pos
			if i % 100 is 0
				pos = random_pos()
				dir = createVector(random(-1, 1), random(-1, 1)).normalize()
			else
				d = p5.Vector.mult(dir, 0.01)
				pos.add d

		@image = createGraphics @size, @size, WEBGL
		@image.setAttributes "alpha", true
		@image.setAttributes "antialias", false


	draw : (delta = 0, time = 0, external = false) ->
		if not external
			@image.clear()
			# @image.background Colors.white

		@image.noStroke()
		# @image.noFill()
		@image.fill 96

		for c in @clouds
			@image.ellipse c.x, c.y, c.z + 6.0
		# @image.blendMode DIFFERENCE
		@image.fill 200
		for c in @clouds
			@image.ellipse c.x, c.y, c.z

	pan : (p) ->
		nt = (@last_cloud + 1) % @clouds.length



		l = @largest / @size
		p.x = constrain p.x, l, 1 - l
		p.y = constrain p.y, l, 1 - l
		r = @size / 20
		pos = createVector p.x * @size + random(- r, r), p.y * @size + random(- r, r)


		if pos.dist(@last_pos) > (@clouds[@last_cloud].z + @clouds[nt].z) * 0.3
			@last_pos = pos
			@clouds[@last_cloud].x = pos.x
			@clouds[@last_cloud].y = pos.y
			@last_cloud = nt
			@draw()

	collide_pos : (p) ->
		for c in @clouds
			collides = collidePointCircle p.x, p.y, c.x, c.y, c.z
			if collides
				return true
		false

	blend : () ->
